
*{
    margin: 0;
    padding: 0;
}

.Snipo_imgBox{
    position: absolute;
    bottom: -80px;
    left: -100px;
    z-index: -1;
}

.text{
    font-size: 17px;
    font-weight: 600;
}
.aboutSide_img{
    max-width: 400px !important;
    margin-left: 80px;
}

.flip_Box_img1{
    max-width: 280px;
    position: absolute;
    top: 20px;
    right: -60px;
    padding: 6px;
}
.flip_Box_img2{
    max-width: 200px;
    position: absolute;
    bottom: 0px;
    left: 60px;
    padding: 6px;
}


/* Media Query */


@media screen and (max-width:1024px) and (min-width:992px) {

    .flip_Box_img1{
        max-width: 200px;
        top: 150px;
        right: 0px;
        padding: 6px;
    }
    .aboutSide_img{
        max-width: 300px !important;
        margin-left: 0px;
    }
    .flip_Box_img2{
        max-width: 150px;
        position: absolute;
        bottom: 80px;
        left: 20px;
        padding: 6px;
    }
}


@media screen and (max-width:992px) and (min-width:768px) {
    .flip_Box_img1{
        max-width: 200px;
        top: -50px;
        right: 20px;
        padding: 6px;
    }
    .aboutSide_img{
        max-width: 400px !important;
        margin-left: 20px;
    }
    .flip_Box_img2{
        max-width: 150px;
        position: absolute;
        bottom: -50px;
        left: 80px;
        padding: 6px;
    }
}

@media screen and (max-width:768px) and (min-width:600px) {
    .flip_Box_img1{
        max-width: 150px;
        top: -50px;
        right: -30px;
        padding: 6px;
    }
    .aboutSide_img{
        max-width: 350px !important;
        margin-left: 20px;
    }
    .flip_Box_img2{
        max-width: 150px;
        position: absolute;
        bottom: -50px;
        left: 20px;
        padding: 6px;
    }
}


@media screen and (max-width:600px) and (min-width:320px) {
    .flip_Box_img1{
       display: none;
    }
    .aboutSide_img{
        max-width: 400px !important;
        margin-left: 20px;
    }
    .flip_Box_img2{
        display: none;
    }
}


@media screen and (max-width:992px) and (min-width:320px) {
    .AboutInfo_box{
        margin-top: 70px;
    }
}
