
/* CEO_Name_font */
 @import url('https://fonts.googleapis.com/css2?family=Sacramento&display=swap');

/* title_font */
/* @import url('https://fonts.googleapis.com/css2?family=Public+Sans:&display=swap'); */



.titleFont_style{
    font-family: 'Public Sans', sans-serif;
    /* font-family: "Poppins", sans-serif; */
}

*{
    --blue: #086ad8;
    --borderRadius: 6px;
    --transition: .3s;
    --whiteClr : white;
}

.section_padding{
    padding: 80px 0;
}


/* =========== Pages Banner CSS ============ */

.pageBanner{
    min-height: 80vh;
    background-size: cover;
    background-position: center;
    position: relative;
}

.bannerCover{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0, 0, 0,.7);
}


/* ================= */


.ColSide_imgBox{
    max-width: 500px !important;
    z-index: 1;
}

.ColSide_img{
    /* border: 2px solid rgb(222, 222, 255); */
    padding: 10px;
    background-color: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.background_Blue{
    background: #086ad8;
}

.background_lightGrey{
    background-color: rgba(116, 178, 250, 0.1);
}

.boxShadow{
    box-shadow: rgba(100, 100, 248, 0.1) 0px 13px 27px -5px, rgba(100, 100, 248, 0.25) 0px 8px 16px -8px;
}

.text_p{
    font-size: 16px;
    /* font-weight: 600; */
    letter-spacing: 1px;
}

.text_color{
    color: rgb(58, 58, 59);
}

.text-width{
    max-width: 900px;
}

.italic{
    font-style: italic;
}

.span_Titles{
    font-size: 18px;
    font-weight: 800 !important;
    letter-spacing: .2px;
    text-transform: capitalize;
}

.UpperCase_title{
    text-transform: uppercase !important;
}

.main_heading{
    font-size: 34px;
    letter-spacing: 0;
    line-height: 46px;
    font-weight: 800;
    font-family: 'Public Sans', sans-serif;
}

.sub_titles{
    text-transform: capitalize;
    letter-spacing: 0px;
    font-size: 32px;
    font-family: 'Public Sans', sans-serif;
}

.small_titles{
    font-size: 22px;
    letter-spacing: 0;
    text-transform: capitalize;
    font-family: 'Public Sans', sans-serif;
}

.Blue_Span{
    color: var(--blue);
}

.purple_text{
    color: rgba(91, 104, 235, 1);
}

.text_gradient{
    background: -webkit-linear-gradient(105deg, rgba(91, 104, 235, 1) 0%, rgba(40, 225, 253, 1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.border_gradient{
    border-image: linear-gradient(to right, #aff7fa 0%, #a5b0fa 100%) 1;
    border-width: 2px;
    border-style: solid;
    padding: 5px;
}

.all_type_list{
    list-style: none;
}

.iconSize{
    font-size: 26px;
}
.iconBlueClr{
    color: var(--blue);
}

.bar{
    width: 60px;
    height: 3px;
    /* background: var(--blue); */
    background: linear-gradient(105deg, rgba(91, 104, 235, 1) 0%, rgba(40, 225, 253, 1) 100%);

}

.boxShadow_none{
    box-shadow: none !important;
}

/* .fontSize_sm{
    font-size: 16px !important;
} */

.accordion-item {
    border-radius: 10px !important;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin: 14px 10px;
}

.accordion-button{
    /* background: #086ad8 !important; */
    background: linear-gradient(105deg, rgba(91, 104, 235, 1) 0%, rgba(40, 225, 253, 1) 100%);
    color: white !important;
    border-radius: 10px;
} 

.accordion-button:focus{
    box-shadow: none !important;
}

.accordion-button::after{
    color: white !important;
}

.accordion-button::after{
    background-image: url('../src/Components/easolPartner/easolAssets/upload.png') !important;
}

.accordion-button:not(.collapsed)::after{
    background-image: url('../src/Components/easolPartner/easolAssets/down-arrow.png') !important;
}

.position_box{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border: 2px solid rgb(0, 0, 255,.2);
    transition: var(--transition);
}

.position_box:hover{
    border: 2px solid var(--blue);
}







/* ==================  Media Query  ================= */

@media screen and (max-width:992px) and (min-width:768px) {
    .pageBanner{
        min-height: 70vh;
    }
}

@media screen and (max-width:768px) and (min-width:300px) {
    .pageBanner{
        min-height: 50vh;
    }
}




@media screen and (max-width:992px) and (min-width:320px) {
    .col_reverse{
        flex-direction: column-reverse;
    }
}

@media screen and (max-width:600px) and (min-width:320px) {
    .section_padding{
        padding: 30px 0;
    }

    .main_heading{
        font-size: 24px;
        line-height: 30px;
    }

    .sub_titles{
        font-size: 26px;
    }
    

}