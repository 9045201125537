*{
    margin: 0;
    padding: 0;
}

.boxWhy{
    min-height: 26vh;
    padding: 16px;
}
.differBox_icons{
    width: 80px;
    height: 80px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -50px;
    right: -20px;
    background: linear-gradient(105deg, rgba(91, 104, 235, 1) 0%, rgba(40, 225, 253, 1) 100%);
}

.short_titles{
    letter-spacing: .1px;
    font-weight: 800 !important;
    font-size: 22px;
}

.p2{
    /* background: rgba(40, 225, 253, .2); */
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    position: relative;
    margin-right: 50px;
    padding: 40px 20px 40px 40px;
    min-height: 30vh;
}




@media screen and (max-width:1024px) and (min-width:300px) {
    .p2{
        margin-right: 0px !important;
    }    
}

@media screen and (max-width:580px) and (min-width:300px) {
    .differBox_icons{
        right: -10px;
        top: -40px;
        width: 60px;
        height: 60px;
    }    
}