*{
    margin: 0;
    padding: 0;
}

.s-box{
    width: 80%;
    margin: auto;
    min-height: 20vh;
    padding-left: 30px;
}

.s-box1{
    background: linear-gradient(to left, rgba(40, 225, 253,.15), white);
    border-top-right-radius: 300px;
    border-bottom-right-radius: 300px;
}

.s-box2{
    background: linear-gradient(to right, rgba(40, 225, 253,.15), white);
    border-top-left-radius: 300px;
    border-bottom-left-radius: 300px;
}

.service_imgBox{
    /* max-width: 500px; */
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    border-radius: 300px;
}

.service_imgBox img{
    border-radius: 300px;
}

.casesList{
    color: black;
    font-weight: 900;
    font-size: 16px;
}