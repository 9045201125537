.slide {
  width: 100% !important;
  margin: 0 !important;
}
.carousel-item{
  height: 50vh !important;
  /* border: 1px solid; */
  background: transparent !important;
}

.carousel-caption{
  background-color: white !important;
  width: 100%;
  top: 0 !important;
  left: 0 !important;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.carousel-indicators [data-bs-target]{
  background-color: rgba(91, 104, 235, 1) !important;
}


@media screen and (min-width:320px) and (max-width:400px) {
  .carousel-item{
    height: 60vh !important;
  }
}