*{
    margin: 0;
    padding: 0;
}

.leaderImg_box{
    max-width: 350px;
    border: 10px solid white;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px; */
    position: relative;
}

.frame{
    width: 100%;
    height: 100%;
    top: 60px;
    left: 70px;
    position: absolute;
    z-index: -1;    
    border: 10px solid transparent; 
    border-image: linear-gradient(105deg, rgba(91, 104, 235, 1) 0%, rgba(40, 225, 253, 1) 100%);
    border-image-slice: 1; 
    padding: 25px; 
}









@media screen and (max-width:992px) and (min-width:768px) {
    .leaderImg_box{
        max-width: 250px;
    }
    .frame{
        left: 40px;
    }
}

@media screen and (max-width:767px) and (min-width:500px) {
    .leaderImg_box{
        max-width: 300px;
    }
    .frame{
        left: 60px !important;
    }
}

@media screen and (max-width:500px) and (min-width:300px) {
    .leaderImg_box{
        max-width: 230px;
    }
    .frame{
        left: 50px !important;
    }
}





/* ===================================================================== */
