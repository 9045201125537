

.companyVideo{
    border-radius: 10px;
}

.CompVideo{
    border: 2px solid white;
    border-radius: 10px;
    padding: 30px;
    max-width: 1000px;
    min-height: 500px;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset; */
    transition: .5s;
    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}

.CompVideo:hover{
    border: 2px solid rgba(40, 225, 253, 1);
}


@media screen and (max-width:600px) {
    .CompVideo{
        padding: 14px;
        /* max-width: 1000px; */
        min-height: 300px;
    }

}


@media screen and (max-width:400px) {

    .CompVideo{
        padding: 10px;
        max-width: 1000px;
        min-height: 240px;
    }
}