
.aspire_bg {
    background-image: url('../workAssets/mockup1.webp');
}

.dino_bg{
    background-image: url('../workAssets/mockup2.webp');
}

.satsung_adv{
    background-image: url('../workAssets/mockup3.webp');
}

.furly{
    background-image: url('../workAssets/furly.webp');
}

.easol{
    background-image: url('../workAssets/easol.webp');
}

.takeLessons{
    background-image: url('../workAssets/takelessons.webp');
}

.servicesBox{
    background-position: center;
    background-size: cover;
    min-height: 34vh;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    position: relative;
    overflow: hidden;
}

.portFolio_overLay{
    background-color: rgb(20, 0, 20 ,.8);
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: var(--transition);
}

.portfolioBox:hover .portFolio_overLay{
    opacity: 1;
}