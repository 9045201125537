
*{
    margin: 0;
    padding: 0;
}

.ab_cont{
    position: relative;
}


.Snipo_imgBox{
    position: absolute;
    bottom: 0px;
    left: 0px !important;
}

.CEO_imgBox{
    max-width: 100px !important;
}

.CEO_imgBox img{
    border: 2px solid var(--blue);
    padding: 4px;
    box-shadow: -1px 2px 10px 2px rgb(152, 152, 253 ,.5);
}

.CEO_Name{
    font-family: 'Sacramento', cursive;
    text-transform: capitalize;
    font-size: 38px;
    color: #07ad94;
}






/* Media Query */


/* @media screen and (max-width:1024px) and (min-width:992px) {
    .Snipo_imgBox{
        bottom: 50px;
        left: -10px;
        z-index: -1;
    }
}

@media screen and (max-width:992px) and (min-width:768px) {
    .Snipo_imgBox{
        bottom: -60px;
        left: -50px;
        z-index: -1;
    }
} */


@media screen and (max-width:600px) and (min-width:320px) {
    .Snipo_imgBox{
        display: none !important;
    }
}

@media screen and (max-width:992px) and (min-width:320px) {
    .AboutInfo_box{
        margin-top: 70px;
    }
}


@media screen and (max-width:435px) and (min-width:320px) {
    .CEO_infoBox{
        flex-direction: column;
    }
    .CEO_Name{
        font-size: 30px;
        padding-top: 20px;
    }
    .CEO_subtitle{
        font-size: 16px;
    }
}