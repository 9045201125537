.about_bg{
    background-image: url('../aboutAssets/pageBanner_Bg.webp');

}


@media screen and (max-width:600px) {
    .text_mb{
        display: none;
    }
}