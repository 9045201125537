.banner{
  border: 1px solid;
  position: relative;
  background-size: cover;
  background-position: center;
  background-image: url('../homePageAssets/c-banner.webp');
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* animation: slider ;
  animation-duration: 20s;
  animation-iteration-count: infinite; */
}

.banner_cover{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0,.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.banner_text{
  font-size: 60px;
  letter-spacing: 0 !important;
}
  
.bold_title{
  font-weight: 1000;
}

.bannerIcon_bar{
  position: absolute;
  left:60px;
  display: flex;
  flex-direction: column;
  width: 50px;
}

/* @keyframes slider {

  0%
  {
    background-image: url('../homePageAssets/banner1.jpg');
  }

  25%
  {
    background-image: url('../homePageAssets/banner2.jpg');
  }

  50%
  {
    background-image: url('../homePageAssets/banner3.jpg');
  }
  
  75%
  {
    background-image: url('../homePageAssets/banner4.jpg');
  }

  100%
  {
    background-image: url('../homePageAssets/banner1.jpg');
  }
  
} */



/* =================== Media Query ======================== */

@media screen and (max-width:992px) and (min-width:320px) {

  .bannerIcon_bar{
    position: absolute;
    left:auto;
    bottom: 0px;
    display: flex !important;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 200px;
  }

}

@media screen and (max-width:992px) and (min-width:600px) {
  .banner_text{
    font-size: 50px;
    letter-spacing: 0 !important;
  }
  
}

@media screen and (max-width:600px) and (min-width:320px) {
  .banner{
    min-height: 80vh;
  }

  .banner_text{
    font-size: 30px;
    letter-spacing: 0 !important;
  }

}



