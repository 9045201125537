.nav-tabs{
    max-width: 500px;
    margin: auto;
    border-bottom: none !important;
    /* background-color: red; */
    background: linear-gradient(105deg, rgba(91, 104, 235, .3) 0%, rgba(40, 225, 253, .3) 100%);
    border-radius: 30px;
    box-shadow: rgba(91, 104, 235, .3) 3px 3px 6px 0px inset, rgba(40, 225, 253, .5) -3px -3px 6px 1px inset;
}

.nav-tabs .nav-link{
    border: none !important;
}

.tb .nav-link{
    color: black !important;
    font-size: 16px;
    font-weight: 600;
}

.tb .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
    color:  white !important;
    background: linear-gradient(105deg, rgba(91, 104, 235, 1) 0%, rgba(40, 225, 253, 1) 100%);
    border-radius: 30px;
    
}