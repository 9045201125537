@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');
*{
    margin: 0;
    padding: 0;
}

.pricing_card{
    background: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    border-radius: 20px;
    position: relative;
    padding: 80px 20px 30px 20px;
}

.pricing_bgVector{
    background-image: url('../easolAssets//pro-table-top.png');
    background-position: center;
    background-size: cover;
    position: absolute;
    width: 281px;
    height: 251px;
    top: 0;
    left: 0;
    border-radius: 20px;

}

.p-txt{
    z-index: 50;
}

.packagePrice_title{
    font-size: 60px;
    color: black;
    font-family: 'Poppins', sans-serif;
    z-index: 50;
}

.pricingText{
    font-size: 14px;
    letter-spacing: 0;
    color: #4b8ef1;
}