.lifeImgs_box{
    height: 40vh;
    background-size: cover;
    background-position: 100%;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    position: relative;
    transition: .5s;
}

.lf_box:hover .lifeImgs_box{
    transform: scale(1.05);
}

.lifeImgs-1{
    background-image: url('../Assets/e6.webp'); 
}

.lifeImgs-2{
    background-image: url('../Assets/e1.webp'); 
}

.lifeImgs-3{
    background-image: url('../Assets/e2.webp'); 
}

.lifeImgs-4{
    background-image: url('../Assets/e3.webp'); 
}

.lifeImgs-5{
    background-image: url('../Assets/e4.webp'); 
}

.lifeImgs-6{
    background-image: url('../Assets/e5.webp'); 
}
