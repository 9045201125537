.lifeAtELite_banner{
    background-image: url('../Assets/pageBanner_Bg.webp');

}

/* .bannerCover{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0, 0, 0,.6);
} */

