*{
    margin: 0;
    padding: 0;
}

.countBox_bg{
    background: #f1f8ff;
    border-top-right-radius: 300px;
    border-bottom-right-radius: 300px;
    width: 90%;
    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 30px 30px -20px, rgba(162, 209, 247, 0.3) 0px 30px 40px -30px;
}

.counter_text{
    font-size: 32px !important;
    line-height: 34px;
}

.thumb_bar{
    width: 250px;
    height: 1px;
    /* background: lightgrey; */
    background: linear-gradient(105deg, rgba(91, 104, 235, 1) 0%, rgba(40, 225, 253, 1) 100%);

    text-align: center;
}

.charts_orb {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    font-family: arial;
}

.charts_orb .orb {
    padding: 20px 50px;
}

.charts_orb .orb .orb_graphic {
    position: relative;
}

.charts_orb .orb .orb_graphic .orb_value {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 26px;
    font-weight: bold;
}

.charts_orb .orb .orb_label {
    text-align: center;
    margin-top: 1em;
}

.charts_orb svg {
    width: 110px;
    height: 110px;
    border-radius: 100px;
    /* box-shadow: rgb(8, 106, 216 ,.8) 2px 25px 30px -20px; */
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}

.charts_orb svg circle {
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    stroke-dasharray: 314.16, 314.16;
    stroke-width: 3;
    fill: transparent;
    r: 50;
    cx: 55;
    cy: 55;
}

.charts_orb svg circle.fill {
    stroke: #c0dbfa;
}

.charts_orb svg circle.progress {
    stroke: rgba(40, 225, 253, 1) ;
    transition: stroke-dashoffset 0.35s;
    stroke-dashoffset: 100;
    animation: NAME-YOUR-ANIMATION 1.5s forwards;
    animation-timing-function: linear;
}

@keyframes NAME-YOUR-ANIMATION {
    0% {
      stroke-dashoffset: 300;
    }

    100% {
      stroke-dashoffset: 100;
    }
  }
  




@media screen and (max-width:600px) and (min-width:450px) {
    .thumb_bar{
        width: 180px !important;
    }
}

@media screen and (max-width:450px) and (min-width:320px) {
    .thumb_bar{
        width: 120px !important;
    }
    .counter_text{
        font-size: 22px !important;
        line-height: 30px;
    }
}