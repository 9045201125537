*{
    margin: 0;
    padding: 0;
}

.white-Btn{
    border: 1px solid rgba(91, 104, 235, 1) !important;
    background-color: white !important;
    color: rgb(79, 92, 243) !important;
    transition: .85s;
    border-radius: 10px !important;
}

.white-Btn:hover{
    background: linear-gradient(105deg, rgba(91, 104, 235, 1) 0%, rgba(40, 225, 253, 1) 100%);
    color: var(--whiteClr) !important;
    transition: .2s;
}

.footer-Section{
    /* padding: 50px 0 20px; */
    /* background-image: url('./assets/build_bg.webp'); */
    background-image: url('./assets/footer-bg.png');
    background-size: cover;
    background-position: center;
    min-height: 112vh;
    padding-top: 450px;
}

.footer_m_title{
    font-family: "Poppins", sans-serif;
}

.row_width{
    width: 70%;
}

.footerBar{
    background: rgb(255, 255, 255,.3);
}

.logo_title{
    font-size: 20px;
}

.footerIcons{
    font-size: 22px;
}

.footer_navigation{
    max-width: 500px;
}


.footer_titles_sm{
    font-size: 22px;
}



/* 
@media screen and (max-width:390px) and (min-width:300px) {
    .footerLogo_res{
        margin-left: 44px;
    }
    .row_width{
    width: 75%;
    }
} */


@media screen and (max-width:600px) and (min-width:300px) {
    .row_width{
        width: 100%;
    }
}