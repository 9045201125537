.arrowDown{
    font-size: 40px;
}
.position_box{
    padding: 30px;
}

.p-cont{
    background-color: white;
    border-radius: 10px;
    padding: 60px 40px;
    margin: 30px auto;
    border: 2px solid rgba(40, 225, 253, .5) ;
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}


.positions_imgBox{
    margin-top: -120px;
    margin-bottom: -120px;
}


.positions_imgBox img{
    border-radius: 30px;
}

.position_title{
    letter-spacing: .1px;
    font-size: 22px;
    font-weight: 800;
    color: black;
    line-height: 1px;
}

.position_txt{
    color: rgb(83, 96, 243);
    font-weight: 600;
}