.map{
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 40px 0px, rgba(14, 30, 37, 0.32) 0px 2px 20px 0px;
    border-radius: 20px;
    padding:10px;
    width:90%;
    height:400px
}


@media screen and (max-width:768px) and (min-width:600px) {
    .map{
        height:400px
    }
}


@media screen and (max-width:600px) and (min-width:320px) {
    .map{
        height:300px;
        width: 100%;
        padding: 0;
    }
}