*{
    margin: 0;
    padding: 0;
}

.headerBlue{
    background-color: var(--blue) !important;
}

.NavigationBar{
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px; */
    /* background-color: var(--blue) !important; */
    padding: 2px 20px 2px 0px !important;
    position: fixed !important;
    z-index: 50;
    width: 100%;
    background-color: transparent;
}

.scrolled{
    /* background-color: var(--blue); */
    background: linear-gradient(105deg, rgba(91, 104, 235, 1) 0%, rgba(40, 225, 253, 1) 100%);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.nav-item {
    margin: 0 !important;
}

.LogoBox{
    max-width: 70px;
}

.margin{
    margin: 0 30px;
}



/* ============ Navigation DropDown =============== */

.navbar-toggler:focus{
    box-shadow: none !important;
}

.navbar-toggler{
    color: white !important;
    border: 1px solid white !important;
    border-radius: 4px;
    /* background-color: white !important; */
}

.navbar-toggler-icon{
    color: white !important;
    background-image: url('../assets/menu.png') !important;
    margin-top: 6px !important;
    width: 1.2em !important;
    height: 1.2em !important;
}

.nav-link{
    color: var(--whiteClr) !important;
}

.dropdown-menu{
    background-color: var(--whiteClr);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: var(--borderRadius);
    transition: var(--transition);
    /* border-bottom: 3px solid var(--blue) !important; */
    border-bottom: 3px solid rgba(91, 104, 235) !important;
    border-top: none !important;
}

.dropMenuList:hover{
    /* background-color: var(--blue) !important; */
    background: linear-gradient(105deg, rgba(91, 104, 235, 1) 0%, rgba(40, 225, 253, 1) 100%);
    color:var(--whiteClr) !important;
    transition: var(--transition);
}


/* ============ Button =============== */

/* .quoteBtn{
    border-radius: var(--borderRadius);
    transition: var(--transition);
    font-size: 16px;
    letter-spacing: 1px;
    background-color: var(--whiteClr) !important;
    border: 2px solid var(--whiteClr) !important;
    color: var(--blue) !important;
}

.quoteBtn:hover{
    border: 2px solid var(--whiteClr) !important;
    color: var(--whiteClr) !important;
    background-color: transparent !important;
} */

.navbar-collapse{
    z-index: 5;
}

.navBtn{
    border: 2px solid var(--whiteClr) !important;
    color: var(--whiteClr) !important;
    background-color: transparent !important;
    transition: .3s;
}

.navBtn:hover{
    background-color: var(--whiteClr) !important;
    border: 2px solid var(--whiteClr) !important;
    color: rgba(91, 104, 235, 1) !important;
}

/* ======================== MEDIQ QUERY ============================================ */

@media screen and (max-width:1084px) and (min-width:320px) {
    .margin{
        margin: 0 10px !important;
    }
}


@media screen and (max-width:992px) and (min-width:320px) {
    .navbar-collapse{
        /* background: var(--blue) !important; */
        background: linear-gradient(105deg, rgba(91, 104, 235, 1) 0%, rgba(40, 225, 253, 1) 100%);
        padding: 10px;
    }
    .NavigationBar{
        height: 12vh !important;
    }
    .nav_list{
        margin-right: 0px !important;
    }
}


@media screen and (max-width:420px) and (min-width:300px) {
    .navBtn{
        display: none !important;
    }
}