*{
    margin: 0;
    padding: 0;
}

.service_sideCont{
    padding-right: 150px;
}

.ss_boxN{
    background-color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 10px;
    padding: 30px 20px;
    border-radius: 10px !important;
    /* height: 33vh; */
}

.Ser_iconImg{
    max-width: 60px;
    padding: 8px;
    border-radius: 30px;
}

.ServiceSide_cols{
    width: 100%;
}

.ServiceSide_col_2{
    margin: -30px 0 0px 20px !important;
}

.topMargin{
    margin-top: 60px;
}







@media screen and (max-width:992px) and (min-width:768px) {
    .fullCol_md{
        width: 100% !important;
    }
    
    .service_sideCont{
        padding-right: 0px;
    }
}


@media screen and (max-width:992px) and (min-width:300px) {
    .service_sideCont{
        padding-right: 0px;
    }
}

@media screen and (max-width:600px) and (min-width:300px) {
    .column_md{
        flex-direction: column;
    }

    .ServiceSide_col_2{
        margin: 20px 0 0 0px !important;
    }

    .topMargin{
        margin-top: 00px;
    }
    
}


/* ================================================================================= */














.cont{
    max-width: 80%;
    margin: auto;
}

/* .outer_box{
    background-color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    padding: 60px 20px;
    position: relative;
    transition: .3s linear;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sBox_imgs{
    max-width: 100px;
}

.inner_box{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    transition: .3s linear;
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    background-position: center;
    background-size: cover;
}

.innerBox_txt P{
    font-size: 14px;
}

.innerBox_txt h4{
    letter-spacing: 1px;
    font-weight: 1000;
}

.inner_box1{
    background-image: url('../homePageAssets/web-deve.webp');
}

.inner_box2{
    background-image: url('../homePageAssets/mobileApp-development.webp');
}

.inner_box3{
    background-image: url('../homePageAssets/call-centre.webp');
}

.inner_box4{
    background-image: url('../homePageAssets/DigitalMarketing.webp');
}


.innerBox_txt{
    background: rgb(0, 0, 0,.7);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    border-radius: 12px;
    background-position: center;
    background-size: cover;
}


.innerBox_btn{
    color: white !important;
    border: 1px solid  white !important;
    padding: 4px 10px !important;
    border-radius: 4px !important;
    text-transform: capitalize !important;
}

.innerBox_btn:hover{
    color:  rgba(91, 104, 235, 1) !important;
    background-color: white !important;
}

.s_BB:hover .inner_box{
    margin-top: -70%;
    visibility: visible;
}

.s_BB:hover .outer_box{
    margin-top: 120px
} */






















/* 

.servicesBox{
    background-position: center;
    background-size: cover;
    min-height: 34vh;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    position: relative;
    overflow: hidden;
}
.servicesBox-1{
    background-image: url('../homePageAssets/web-deve.webp');
}

.servicesBox-2{
    background-image: url('../homePageAssets/mobileApp-development.webp');
}

.servicesBox-3{
    background-image: url('../homePageAssets/UIUX-Design.webp');
}

.servicesBox-4{
    background-image: url('../homePageAssets/DigitalMarketing.webp');
}

.servicesBox-5{
    background-image: url('../homePageAssets/call-centre.webp');
}

.servicesBox-6{
    background-image: url('../homePageAssets/lead.webp');
}


.cover_Overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.services_overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0,.4);
    top: 0;
    left: 0;
}


.servicesInfo_overLay{
    background-color: rgb(0, 0, 0,.8);
    bottom: -100px !important;
    transition: var(--transition);
    top: -100%;
}

.servicesBox:hover .servicesInfo_overLay{
    top: 0;
}

.servicesBox_btn{
    color: white !important;
    background: transparent !important;
}

.servicesBox_btn:hover{
    background: var(--blue) !important;
    border: 2px solid var(--blue) !important;
} */



.blue_btn{
    background: linear-gradient(to right, rgba(91, 104, 235, 1) 0%, rgba(40, 225, 253, 1) 100%);
    color: white !important;
}

.blue_btn:hover{
    background: linear-gradient(to left, rgba(91, 104, 235, 1) 0%, rgba(40, 225, 253, 1) 100%);

}